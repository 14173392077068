import OneSignal from 'react-onesignal';
/**
 * This application could be run in stoerk, nuttall, ocf or hengel
 * All domains would need a unique configuration because OneSignal
 * allow one domain per application
 *
 * All the applications are configured with the account
 *
 * ## Supported sites
 *
 * https://foursfringand-cloud.fr/
 * https://serenityconnect.eu/
 * https://ocf.commander-cloud.eu/
 * https://hengel.commander-cloud.eu/
 * https://flexeservenuttalls.cloud/
 * https://flexeserve.cloud/
 * https://nuttalls.cloud/
 *
 * ### Stoerk:
 * https://playground.commander-cloud.eu/
 * https://staging.commander-cloud.eu/
 * https://commander-cloud.eu/
 * https://commander-cloud.com/
 * https://commander-cloud.de/
 * https://commander-cloud.net/
 * https://stoerk-tronic.cloud/
 * https://s-t.cloud/
 */
export function getConfigurationByDomain(hostname = window.location.hostname) {
  switch (hostname) {
    case 'www.foursfringand-cloud.fr':
    case 'foursfringand-cloud.fr':
      return new OneSignalConfiguration(
        '64fc69b7-ab6d-4917-a896-05ec03bd67d9',
        'web.onesignal.auto.4bf12d4e-2e1c-4e2f-be7e-e4e315c9ca64'
      );
    case 'www.serenityconnect.eu':
    case 'serenityconnect.eu':
      return new OneSignalConfiguration(
        '559c573b-8a27-43c3-a692-12395dbad2b2',
        'web.onesignal.auto.4d177f4c-af32-40a6-bcd9-e75371e6c146'
      );
    case 'www.ocf.commander-cloud.eu':
    case 'ocf.commander-cloud.eu':
      return new OneSignalConfiguration(
        'e468254f-2cfd-48ec-b32a-0c30a63b2229',
        'web.onesignal.auto.3a850f03-75f9-40a0-acb5-2bc8b318c823'
      );

    case 'www.hengel.commander-cloud.eu':
    case 'hengel.commander-cloud.eu':
      return new OneSignalConfiguration(
        '1423cdde-cf3c-44fa-87a5-207061b917f2',
        'web.onesignal.auto.0893d0c7-c315-4498-a086-d9071f0f29e9'
      );
    case 'www.flexeservenuttalls.cloud':
    case 'flexeservenuttalls.cloud':
      return new OneSignalConfiguration(
        '6d0d3248-e190-4293-b470-77638827e979',
        'web.onesignal.auto.5b1b15a7-d107-41ff-b02e-c379c8847bd2'
      );
    case 'www.flexeserve.cloud':
    case 'flexeserve.cloud':
      return new OneSignalConfiguration(
        '06a8f06c-428e-4612-b2d5-7a547cf9cda3',
        'web.onesignal.auto.2c2f7f93-c22c-4405-a44b-05589b796f38'
      );
    case 'www.nuttalls.cloud':
    case 'nuttalls.cloud':
      return new OneSignalConfiguration(
        '79b9e710-e93a-4705-b791-bf26a1cbecf7',
        'web.onesignal.auto.1a1cd43f-104c-4ac2-b069-e07775f1eeb9'
      );
    case 'www.playground.commander-cloud.eu':
    case 'playground.commander-cloud.eu':
      return new OneSignalConfiguration(
        'fe6b8a3a-8fe6-4465-9dd9-6ffd57a79417',
        'web.onesignal.auto.3182d724-6e8d-450b-a283-f7f35292ae01'
      );
    case 'www.commander-cloud.eu':
    case 'commander-cloud.eu':
      return new OneSignalConfiguration(
        'ecb7cacc-5d1b-4b00-90aa-f382fa2810e3',
        'web.onesignal.auto.21f9ce54-828a-4883-b11b-dd6935813645'
      );
    case 'www.commander-cloud.com':
    case 'commander-cloud.com':
      return new OneSignalConfiguration(
        '1af3553b-197c-48ed-8650-3944c2d29411',
        'web.onesignal.auto.66c7fbb6-f0f6-47ab-9f8e-1bd725d1f3d2'
      );
    case 'www.commander-cloud.de':
    case 'commander-cloud.de':
      return new OneSignalConfiguration(
        'b3c3e6f7-d943-4732-955c-6bbabd842dc4',
        'web.onesignal.auto.5f176c09-6482-49c9-87ea-0c57aa3981a0'
      );
    case 'www.commander-cloud.net':
    case 'commander-cloud.net':
      return new OneSignalConfiguration(
        'fb2425bb-79f0-4fa5-8f04-8837508977ce',
        'web.onesignal.auto.43f3e2d2-3818-43ea-aa50-e469d0e1e106'
      );
    case 'www.staging.commander-cloud.eu':
    case 'staging.commander-cloud.eu':
      return new OneSignalConfiguration(
        'bc631c62-8f28-4f04-95d5-a2bbd5dd39ec',
        'web.onesignal.auto.28671d66-3da8-4a50-bcc4-1b29e015670b'
      );
    case 'www.localhost':
    case 'localhost':
    default:
      return new OneSignalConfiguration(
        'cdc9315a-8cab-4645-98f6-f89ea466f06a',
        'web.onesignal.auto.2b467c5d-2ccd-4e09-a57b-cb7ab9efd0c0'
      );
    case 'www.stoerk-tronic.cloud':
    case 'stoerk-tronic.cloud':
      return new OneSignalConfiguration(
        'eef44e37-ef60-4490-bd46-fe8bdfd14e89',
        'web.onesignal.auto.002ea938-3ebd-4740-ada1-6c17c5eb4600'
      );
    case 'www.s-t.cloud':
    case 's-t.cloud':
      return new OneSignalConfiguration(
        '8923bddb-2d39-4163-ab5c-ac43c5082d12',
        'web.onesignal.auto.3a869b75-1942-4792-8237-9de8041a4c17'
      );
  }
}

type IInitObject = Parameters<typeof OneSignal.init>[0];
export class OneSignalConfiguration implements IInitObject {
  constructor(public appId: string, public safari_web_id: string) {}
  // TODO: delete me it is only for debug
  allowLocalhostAsSecureOrigin = true;
}
