import { createSelector } from '@reduxjs/toolkit';
import {
  navigationPath,
  NavigationPathProps,
} from '../../../../navigations/navigationPath';
import {
  getDeviceIdFromQueryParam,
  getDeviceSelectedName,
} from '../../../../../../redux/devices/selectors';
import {
  getGroupIdQueryParam,
  getGroups,
  getGroupSelectedByQueryParamSelector,
} from '../../../../../../redux/groups/selectors';
import { RootState } from '../../../../../../redux/store.model';
import { getFeatureToggle } from '../../../../../../featureToggle';

const dicTitles: Record<string, string> = {
  history: 'device.monitoring.title',
  incidents: 'incident_manager.title',
};

const getProps = (_: RootState, props: NavigationPathProps) => props;

export const getDeviceSectionsNavigationSelector = createSelector(
  [
    getProps,
    getGroupIdQueryParam,
    getDeviceIdFromQueryParam,
    getGroups,
    getGroupSelectedByQueryParamSelector,
    getDeviceSelectedName,
    getFeatureToggle,
  ],
  (props, groupId, deviceId, groups, group, deviceName, featureToggle) => {
    const url = groupId
      ? `/devicemanager/device/${deviceId}/group/${groupId}/device-detail/`
      : `/devicemanager/device/${deviceId}/device-detail/`;
    const navigators = navigationPath(props, groupId, groups, group).concat([
      {
        content: deviceName || '...',
        // @ts-ignore
        onClick: featureToggle.ControlUnitsOverview
          ? () => {
              history.push(url);
            }
          : undefined,
      },
    ]);
    /** module selected */
    const {
      polyglot,
      history,
      location: { pathname },
    } = props;
    const moduleSelected = pathname.split('device-detail/')[1] ?? '';

    if (moduleSelected) {
      navigators.push({
        content: polyglot.t(dicTitles[moduleSelected] ?? ''),
        onClick: () => history.push(`${url}/${moduleSelected}`),
      });
    }
    return navigators;
  }
);
