import { Box } from '@mui/material';
import React from 'react';
import {
  Redirect,
  RouteComponentProps,
  useParams,
  withRouter,
} from 'react-router-dom';
import { Loader } from 'semantic-ui-react';
import { UiMessage } from 'stoerk-ui-components';
import withHandlingErrors, {
  HandlingErrorWrappedProps,
} from '../../../../../../../../handlingErrors';
import {
  PolyglotComponentProps,
  withPolyglot,
} from '../../../../../../../../i18n';
import { useControlUnitsById } from '../../../../../../../../redux/controlUnit/hooks/useControlUnitsById';
import ControlUnitCard from './components/ControlUnitCardGrid';
import { useAppSelector } from '../../../../../../../../redux/store.model';
import { getFeatureToggle } from '../../../../../../../../featureToggle';
export interface QueryParams {
  groupId?: string;
  deviceId: string;
}

interface DeviceOverviewPageSimpleProps
  extends PolyglotComponentProps,
    RouteComponentProps<QueryParams>,
    HandlingErrorWrappedProps {}

export function DeviceOverviewPageSimple(props: DeviceOverviewPageSimpleProps) {
  const {
    match: {
      params: { deviceId, groupId },
    },
    handlingErrorsApi,
    polyglot,
  } = props;
  const [controlUnits, loadingControlUnits] = useControlUnitsById(
    deviceId,
    handlingErrorsApi,
    true
  );
  const featureToggle = useAppSelector(getFeatureToggle);
  const params = useParams<{ deviceId: string; groupId: string }>();
  if (!featureToggle?.ControlUnitsOverview) {
    const url = params.groupId
      ? `/devicemanager/${params.groupId}`
      : `/devicemanager/`;
    return <Redirect to={url} />;
  }

  if (loadingControlUnits)
    return (
      <Box textAlign={'center'} margin={2}>
        <Loader active inline />
      </Box>
    );
  if (!controlUnits) return null;
  if (controlUnits.length === 0)
    return (
      <Box margin={2}>
        <UiMessage
          attached
          icon="warning"
          header=""
          content={polyglot.t('device.controlUnit.empty')}
        />
      </Box>
    );

  return (
    <Box
      className={'DeviceOverviewPageSimple'}
      sx={{
        margin: 1,
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center',
      }}
    >
      {controlUnits.map((controlUnit) => {
        return (
          <ControlUnitCard
            key={controlUnit.id}
            controlUnit={controlUnit}
            deviceId={deviceId}
            groupId={groupId}
          />
        );
      })}
    </Box>
  );
}

export default withPolyglot(
  withRouter(withHandlingErrors(DeviceOverviewPageSimple))
);
